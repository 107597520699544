
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "CMSPage",
  props: ["pageId", "pageIdentifier"],
  setup(props) {
    const route = useRoute();
    const identifier = ref(route.params.identifier);
    const pageData = ref(null);

    const getCmsPage = async () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("cmsPage"), {
          identifier: identifier.value,
        })
        .then((response: any) => {
          if (response.success) {
            pageData.value = response.page_data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const ionViewWillEnter = () => {
      pageData.value = null;
      identifier.value = props.pageIdentifier;
      getCmsPage();
    };

    return {
      pageData,
      ionViewWillEnter,
    };
  },
  components: {
    IonPage,
    IonContent,
    MainHeader,
    MainFooter,
  },
});
