import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-50" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "col-xl-10 col-lg-12 m-auto bg-white"
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.pageData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("h1", {
                          textContent: _toDisplayString(_ctx.pageData.title)
                        }, null, 8, _hoisted_6),
                        _createElementVNode("div", {
                          innerHTML: _ctx.pageData.content_html
                        }, null, 8, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_8, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}